import React, { Dispatch, SetStateAction, useEffect } from "react"
import { styled, ThemeProvider, DarkTheme } from "baseui"
import { Theme } from "baseui/theme"
import useDesignEditorContext from "~/hooks/useDesignEditorContext"
import { useEditor } from "@layerhub-io/react"
import { IScene } from "@layerhub-io/types"
import { loadTemplateFonts } from "~/utils/fonts"
import { loadVideoEditorAssets } from "~/utils/video"
import { IDesign } from "~/interfaces/DesignEditor"
import { useDispatch, useSelector } from "react-redux"
import { authSelector, isOwnerSelector } from "~/store/slices/auth/selectors"
import { setAuth } from "~/store/slices/auth/actions"
import { sections } from "../../../../components/Menu/data"
import Menu2 from "../../../../components/Menu/MenuComponent"
import {
  Typography,
  Button,
  Avatar,
  Dialog,
  DialogTitle,
  TextField,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  IconButton,
  CircularProgress,
} from "@mui/material"
import { useState } from "react"
import PersonIcon from "@mui/icons-material/Person"
import {
  Menu as MenuIcon,
  Logout as LogoutIcon,
  AccountCircle as AccountCircleIcon,
  ArrowDropDown,
  AddIcCallOutlined,
  Add,
  CloudSyncOutlined,
  CloudUploadOutlined,
  History,
  SaveAsOutlined,
  LaunchOutlined,
  CheckCircleOutlined,
  ArrowDropUp,
} from "@mui/icons-material"
import BrandLogo from "../../../../../public/assets/logos/brand-logo.png"
import useDesignEditorPages from "~/hooks/useDesignEditorScenes"
// import AccountDropdown from "~/components/Menu/DropdownMenu"
import DesignTitle, { syncDesignData } from "./DesignTitle"
import { createNewProject, getAllProjects, updateSelectedProject } from "~/services/digital-assets"
import { selectedProject } from "~/store/slices/project/selectors"
import FileComponent from "~/components/Menu/FileComponent"
import AccountDropdown from "./AccountDropdown"
import AccountIcon from "./AccountIcon"
import Help from "./Help"
import Create from "./Create"
import SearchComp from "./search"
import SolutionsComp from "./Solutions"
import ResourcesMenu from "./Resources"
import ChromeWebstore from "./ChromeStore"
import SolutionsData from "./solutionsData"
import ResourcesData from "./ResourcesData"
import NotificationsComp from "~/components/Menu/Navbar/Notifications"

const BOOKS_SUBDOMAIN = ""
const MAIN_DOMAIN = "https://www.selfpublishingtitans.com"
import TUser from "~/models/user"

export enum EDropdown {
  notification = "notification",
  create = "create",
  help = "help",
  chrome = "chrome",
}

let menuItems: any = {
  tools2: Menu2,
  file: FileComponent,
  solutions: SolutionsComp,
  resources: ResourcesMenu,
}

const NAVLIST = [
  {
    name: "File",
    dropdownIndex: 0,
    menuName: "file",
    dropdownMenu: true,
  },
  {
    name: "Tools",
    dropdownIndex: 5,
    dropdownMenu: true,
    menuName: "solutions",
  },
  {
    name: "Resources",
    dropdownIndex: 6,
    dropdownMenu: true,
    menuName: "resources",
  },
  {
    name: "Pricing",
    href: MAIN_DOMAIN + "/pricing",
    newWindow: true,
  },
  {
    name: "Testimonials",
    href: MAIN_DOMAIN + "/testimonials",
  },
  // {
  //   name: "Titans Ultra",
  //   href: "/pricing"
  // },
  // {
  //   name: "Masterclass",
  //   href: MAIN_DOMAIN + "/masterclass",
  //   newWindow: true,
  // },
  // {
  //   name: "Tools",
  //   href: "/tools",
  //   dropdownIndex: 5,
  //   dropdownMenu: true,
  //   menuName: "tools2",
  // },
  // {
  //   name: "Shop",
  //   href: MAIN_DOMAIN + "/shop",
  //   newWindow: true,
  // },
  // {
  //   name: "Blog",
  //   href: MAIN_DOMAIN + "/blog",
  //   newWindow: true,
  // },

  // {
  //   name: "Support",
  //   href: MAIN_DOMAIN + "/support",
  //   newWindow: true,
  // },
  // {
  //   name: "Affiliates",
  //   href: "https://affiliates.selfpublishingtitans.com/",
  //   newWindow: true,
  // },
]

// @ts-ignore
const MobileNavList = [
  // {
  //   name: "Tools",
  //   href: "#",
  //   dropdownIndex: 3,
  //   isOnlyMobile: true,
  //   dropdown: [...sections],
  // },
  {
    name: "Tools",
    href: "#",
    dropdownIndex: 3,
    isOnlyMobile: true,
    dropdown: [...SolutionsData],
  },
  {
    name: "Resources",
    href: "#",
    dropdownIndex: 4,
    isOnlyMobile: true,
    dropdown: [...ResourcesData],
  },
]
// @ts-ignore
const DROPDOWNCOUNT = NAVLIST.filter((item) => item?.dropdown).length
const DROPDOWNLIST = Array.from(Array(DROPDOWNCOUNT).keys()).map(() => false)

export function NavLink({ href, name, refresh, target, icon }: any) {
  return refresh ? (
    <a href={href} target={target} rel="noreferrer">
      <Typography className="cursor-pointer block py-2 text-sm pr-4 pl-3 text-gray-700 border-b border-secCol1-50 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-secCol1">
        {name} {icon}
      </Typography>
    </a>
  ) : (
    <li className="cursor-pointer block py-2 text-xs pr-4 pl-3 text-gray-700 border-b border-secCol1-50 hover:bg-gray-100  lg:border-0 lg:hover:text-secCol1-700 ">
      <a href={href} className="align-middle items-center flex gap-2">
        {icon}

        <Typography variant="body1" className="text-sm">
          {name}
        </Typography>
      </a>
    </li>
  )
}

// create a new project

// file name
// create project

export function CreateProjectModal({
  token,
  setIsOpen,
}: {
  token?: string | null
  setIsOpen: Dispatch<SetStateAction<boolean>>
}) {
  const [name, setName] = useState("")

  function createProject() {
    if (!token) {
      return
    }

    createNewProject({ token, project_name: name })
      .then((data: any) => {
        console.log("Created new project", data)
        // update selected project
        window.history.pushState({}, "", `?project_id=${data.data.id}`)
        window.location.reload()
      })
      .catch((e) => {
        console.error("Failed to create new project", e)
      })
      .finally(() => {
        setIsOpen(false)
      })
  }

  return (
    <Dialog open={true}>
      <DialogTitle>Create New Project</DialogTitle>
      <div className="flex flex-col w-full px-4 py-2">
        <div className="flex flex-col">
          <Typography variant="body1" className="text-sm">
            Project Name
          </Typography>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="border border-gray-300 rounded-md p-2"
          />
        </div>
        <div className="flex gap-2 w-fit mt-4">
          <Button variant="contained" className="mt-2" onClick={() => setIsOpen(false)}>
            Cancel
          </Button>
          <Button variant="contained" className="mt-2" onClick={createProject}>
            Create Project
          </Button>
        </div>
      </div>
    </Dialog>
  )
}

export function OpenRecentFileModal({
  token,
  setIsOpen,
}: {
  token?: string | null
  setIsOpen: Dispatch<SetStateAction<boolean>>
}) {
  // get recent files

  if (!token) {
    return null
  }

  const [recentFiles, setRecentFiles] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    getAllProjects(token)
      .then((data: any) => {
        setRecentFiles(data.data)
      })
      .catch((e) => {
        console.error("Failed to get recent files", e)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  const handleUpdate = (project: any) => {
    console.log("Updating selected project", project)
    updateSelectedProject({ token, project_id: project.id })
      .then((data: any) => {
        console.log("Updated selected project", data)
        // update selected project
        window.history.pushState({}, "", `?project_id=${data.data.id}`)
        window.location.reload()
      })
      .catch((e) => {
        console.error("Failed to update selected project", e)
      })
      .finally(() => {
        setIsLoading(false)
        setIsOpen(false)
      })
  }

  // handle search bar
  const [search, setSearch] = useState("")

  // sort by selected
  recentFiles.sort((a: any, b: any) => {
    if (a.is_selected) {
      return -1
    }
    if (b.is_selected) {
      return 1
    }
    return 0
  })

  const filteredFiles = recentFiles.filter((file: any) =>
    file.project_name.toLowerCase().includes(search.toLowerCase())
  )

  return (
    <Dialog open={true}>
      <DialogTitle>Open Recent File</DialogTitle>
      <DialogContent>
        <div className="w-full justify-center flex-col items-center">
          {/* search bar */}
          <TextField
            label="Search"
            variant="outlined"
            className="px-4"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />

          <div className="flex justify-center ">
            {isLoading && (
              <div className="p-4">
                {" "}
                <CircularProgress />{" "}
              </div>
            )}
          </div>

          <List className="flex flex-col w-full px-4 py-2">
            {/* <div className="flex flex-col"> */}
            {filteredFiles.map((file: any) => (
              <ListItem
                key={file.id}
                disableGutters
                onClick={() => handleUpdate(file)}
                className="flex gap-2"
                secondaryAction={
                  <IconButton onClick={() => handleUpdate(file)}>
                    {file.is_selected ? <CheckCircleOutlined color="secondary" /> : <LaunchOutlined />}
                  </IconButton>
                }
              >
                {/* <Button onClick={() => handleUpdate(file)} className="flex gap-2">
                  {file.project_name}

                  {file.is_selected ? <CheckCircleOutlined color="secondary" /> : <LaunchOutlined />}
                </Button> */}
                <ListItemText primary={file.project_name} secondary={new Date(file.updatedAt).toDateString()} />
                {/* <Typograph>
                  {file.project_name}
                </Typograph>
                <Button variant="contained" className="mt-2" onClick={() => updateSelectedProject(file)}>
                  Open
                </Button> */}
              </ListItem>
            ))}
            {/* </div> */}
          </List>
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => setIsOpen(false)}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

function NavItemComponent(
  isShowMobile: any,
  toggleDropdown: any,
  isShowDropDown: any,
  staticPage: any,
  info: any,
  router: any,
  session: any,
  navbar: any,
  handleLogout: any
) {
  // if mobile navbar add some links
  if (isShowMobile) {
    // @ts-ignore
    if (!NAVLIST.includes(MobileNavList[0])) {
      // @ts-ignore
      NAVLIST.splice(3, 0, ...MobileNavList)
    }
  } else {
    for (let i = 0; i < NAVLIST.length; i++) {
      // @ts-ignore
      if (NAVLIST[i].isOnlyMobile) {
        NAVLIST.splice(i, 1)
      }
    }
  }
  const [isShowChildDropDown, setIsShowChildDropDown] = useState<any>({})
  const toggleChildDropdown = (index: any) => {
    setIsShowChildDropDown((prevState: any) => {
      const newState = Object.keys(prevState).reduce((acc: any, key) => {
        acc[key] = false
        return acc
      }, {})
      newState[index] = !prevState[index]
      return newState
    })
  }

  const token = useSelector(authSelector).token

  const [activeDropDown, setActiveDropDown] = useState({
    notification: false,
    create: false,
    help: false,
    chrome: false,
  })

  const toggleDropDown = (type: EDropdown) => {
    setActiveDropDown((prevState) => {
      const newState = Object.keys(prevState).reduce((acc, key) => {
        acc[key as EDropdown] = false
        return acc
      }, {} as { [key in EDropdown]: boolean })

      return {
        ...newState,
        [type]: !prevState[type],
      }
    })
  }

  return (
    <div
      className={`${isShowMobile ? "" : "hidden"} justify-between items-center w-full lg:flex lg:w-auto lg:order-1`}
      id="mobile-menu-3"
    >
      {/* file */}
      {/* <CreateProjectModal /> */}
      {/* <OpenRecentFileModal token={token} /> */}
      {/* <Button
        onClick={() => setIsFileOpen(!isFileOpen)}
        style={{
          textDecoration: "none",
          color: "white",
        }}
        onMouseEnter={() => }
        id="dropdownButton"
        className="cursor-pointer block py-2  text-sm pr-4 pl-3 border-b text-white border-secCol1-50 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-[#d9d9d9]"
      >
        <Typography variant="body1" className="text-sm">
          File
        </Typography>
        
      </Button> */}
      {/* {
        isFileOpen && (
          <FileComponent token={token} syncData={syncData} setIsFileOpen={setIsFileOpen} />
        )
      } */}
      {/* {newFunction(setIsCreateNewProject, setIsRecentFileOpen, syncData)} */}
      {NAVLIST.map((item: any, index) =>
        item.dropdown ? (
          <li
            className="relative cursor-pointer block py-2 text-sm pr-4 pl-3 border-b border-secCol1-50 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-secCol1-700"
            onMouseEnter={() => window.innerWidth > 768 && toggleDropdown(item.dropdownIndex)}
            key={`nav_${index}`}
          >
            <div
              onClick={() =>
                isShowDropDown[item.dropdownIndex] ? toggleDropdown() : toggleDropdown(item.dropdownIndex)
              }
              className={`flex justify-between`}
            >
              <a href={item.href || "#"} target={item.newWindow ? "_blank" : "_self"} rel="noreferrer">
                {item.name}
              </a>
              <svg className={`w-5 h-4  text-gray-500 md:text-white inline-block ml-1`} viewBox="0 0 20 20" fill="gray">
                <path
                  fillRule="evenodd"
                  d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>

            <div
              className={`animate__animated animate__fadeIn animate__faster ${
                isShowDropDown[item.dropdownIndex] ? "" : "hidden"
              } block  lg:absolute left-0 right-0 top-10  z-10 w-full lg:w-44 text-base list-none test-class rounded-lg bg-white divide-y divide-secCol1-50 lg:shadow`}
              // onMouseLeave={() => toggleDropdown()}
            >
              <ul className="py-1 pl-8 lg:pl-0 rounded-lg" aria-labelledby="dropdownButton">
                {item.dropdown.map((dropdownItem: any, dii: any) => {
                  return (
                    <>
                      <div
                        key={dii}
                        className={`flex justify-between`}
                        onClick={(e) => {
                          e.stopPropagation()
                          isShowChildDropDown[dii] ? toggleChildDropdown(dii) : toggleChildDropdown(dii)
                        }}
                      >
                        <p className="text-sm my-1 py-1">{dropdownItem.Title}</p>
                        <svg
                          className={`w-5 h-4  text-gray-500 md:text-white inline-block ml-1`}
                          viewBox="0 0 20 20"
                          fill="gray"
                        >
                          <path
                            fillRule="evenodd"
                            d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                      </div>
                      <div
                        className={`animate__animated animate__fadeIn animsate__faster ${
                          isShowChildDropDown[dii] ? "" : "hidden"
                        } block  lg:absolute left-0 right-0 top-10  z-10 w-full lg:w-44 text-base list-none text-black lg:text-white test-class rounded-lg bg-white divide-y divide-secCol1-50 lg:shadow`}
                      >
                        <ul className="py-1 pl-8 lg:pl-0 rounded-lg" aria-labelledby="dropdownButton">
                          {dropdownItem.Categories.map((dropdownItem2: any, diii: any) => {
                            return (
                              <div key={diii}>
                                {dropdownItem2.Heading && (
                                  <p className="text-sm font-medium my-1 py-1">{dropdownItem2.Heading}</p>
                                )}
                                {dropdownItem2.Items.map((item: any, diiii: any) => {
                                  return (
                                    <div
                                      onClick={(e) => {
                                        e.stopPropagation()
                                      }}
                                      key={diiii}
                                      className=""
                                    >
                                      <NavLink
                                        key={diiii}
                                        name={item.heading}
                                        href={""}
                                        refresh={false}
                                        target={dropdownItem.newWindow ? "_blank" : "_self"}
                                      />
                                    </div>
                                  )
                                })}
                              </div>
                            )
                          })}
                        </ul>
                      </div>
                    </>
                  )
                })}
              </ul>
            </div>
          </li>
        ) : item.dropdownMenu ? (
          <li
            className={`relative hidden cursor-pointer md:block py-2  text-sm pr-4 pl-3 border-b border-secCol1-50 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 `}
            onMouseEnter={() =>
              isShowDropDown[item.dropdownIndex] ? toggleDropdown() : toggleDropdown(item.dropdownIndex)
            }
            onMouseLeave={() => toggleDropdown()}
            key={`nav_${index}`}
          >
            <div className={`flex text-black lg:text-white justify-between ${navbar ? "lg:hover:text-[#d9d9d9]" : ""}`}>
              <p>{item.name}</p>
              <svg className={`w-4 h-4  text-gray-500 md:text-white inline-block ml-1`} viewBox="0 0 20 20" fill="gray">
                <path
                  fillRule="evenodd"
                  d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                  clipRule="evenodd"
                ></path>
              </svg>
              {/* <KeyboardArrowDownIcon className="text-gray-500 w-4  inline-block ml-1" /> */}
            </div>

            <div
              style={{
                boxShadow: "2px 2px 20px 2px rgba(0, 0, 0, 0.3), 0 4px 6px -2px rgba(0, 0, 0, 0.2)",
              }}
              className={`animate__animated animate__fadeIn animate__faster rounded-lg 
  ${isShowDropDown[item.dropdownIndex] ? "" : "hidden"}
  fixed top-[calc(100% - 100px)] left-[2%] transform -translate-x-1/2 mt-2 br-16  max-w-[75w] z-50 text-base list-none bg-white divide-y divide-secCol1-50 lg:shadow`}
              // onMouseLeave={() => toggleDropdown()}
            >
              {menuItems[item.menuName]()}
            </div>
          </li>
        ) : (
          <li
            key={`nav_${index}`}
            onMouseEnter={() => toggleDropdown()}
            className={`cursor-pointer block py-2  text-black lg:text-white text-sm pr-4 pl-3 border-b border-secCol1-50 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 ${
              navbar ? "lg:hover:text-[#d9d9d9]" : ""
            }`}
          >
            <a href={item.href || "#"} target={item.newWindow ? "_blank" : "_self"} rel="noreferrer">
              {item.name}
            </a>
          </li>
        )
      )}
      {!staticPage && (
        <li className="block lg:hidden cursor-pointer text-right py-2 text-sm pr-4 pl-3 text-gray-700 border-b border-secCol1-50 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-secCol1-700 lg:p-0 ">
          {session ? (
            <div className="text-sm flex justify-end">
              {/* <Create
                toggleDropDown={toggleDropDown}
                show={activeDropDown.create || false}
                transparentNav={false}
              /> */}
              {/* <ChromeWebstore /> */}
              {/* <Upgrade info={info} /> */}
              <a href={MAIN_DOMAIN + `/auth/me`}>
                <Button variant="contained" className="br-16  rounded-md">
                  Account
                </Button>
              </a>{" "}
              <Button
                variant="outlined"
                className=" text-secCol1-700 ml-4"
                onClick={() => {
                  handleLogout()
                }}
              >
                Logout
              </Button>
            </div>
          ) : (
            <>
              <a href={MAIN_DOMAIN + `/auth/register?next=${router.asPath}`}>
                <Button variant="outlined" className=" br-16">
                  Sign Up For Free
                </Button>
              </a>

              {/* <Link
            passHref
            href={`/auth/login?next=${router.asPath}`}
            
          > */}
              <Button variant="contained" className="ml-3">
                Sign in
              </Button>
              {/* <button className="text-sm">Login</button> */}
              {/* </Link> */}
            </>
          )}
        </li>
      )}
    </div>
  )
}

interface INavbar {
  position?: string
  color?: string
  transparentNav?: boolean
  info?: TUser
  staticPage?: boolean
}

const Navbar = ({
  position = "sticky top-0 w-full",
  color = "bg-white border-b border-gray-200 shadow-lg",
  transparentNav = false,
  info,
  staticPage = false,
}: INavbar) => {
  const { setScenes, setCurrentDesign } = useDesignEditorContext()
  const editor = useEditor()
  // editor.scene
  // const [isAuthenticated, setIsAuthenticated] = React.useState(false)
  const isAuthenticated = useSelector(authSelector).isAuthenticated

  const user = useSelector(authSelector)

  const isOwner = useSelector(isOwnerSelector).isOwner
  const isOwnerDirty = useSelector(isOwnerSelector).isDirty
  const dispatch = useDispatch()

  const loadGraphicTemplate = async (payload: IDesign) => {
    const scenes = []
    const { scenes: scns, ...design } = payload

    for (const scn of scns) {
      const scene: IScene = {
        name: scn.name,
        frame: payload.frame,
        id: scn.id,
        layers: scn.layers,
        metadata: {},
      }
      const loadedScene = await loadVideoEditorAssets(scene)
      await loadTemplateFonts(loadedScene)

      const preview = (await editor.renderer.render(loadedScene)) as string
      scenes.push({ ...loadedScene, preview })
    }

    return { scenes, design }
  }

  const loadPresentationTemplate = async (payload: IDesign) => {
    const scenes = []
    const { scenes: scns, ...design } = payload

    for (const scn of scns) {
      const scene: IScene = {
        name: scn.name,
        frame: payload.frame,
        id: scn,
        layers: scn.layers,
        metadata: {},
      }
      const loadedScene = await loadVideoEditorAssets(scene)

      const preview = (await editor.renderer.render(loadedScene)) as string
      await loadTemplateFonts(loadedScene)
      scenes.push({ ...loadedScene, preview })
    }
    return { scenes, design }
  }

  const loadVideoTemplate = async (payload: IDesign) => {
    const scenes = []
    const { scenes: scns, ...design } = payload

    for (const scn of scns) {
      const design: IScene = {
        name: "Awesome template",
        frame: payload.frame,
        id: scn.id,
        layers: scn.layers,
        metadata: {},
        duration: scn.duration,
      }
      const loadedScene = await loadVideoEditorAssets(design)

      const preview = (await editor.renderer.render(loadedScene)) as string
      await loadTemplateFonts(loadedScene)
      scenes.push({ ...loadedScene, preview })
    }
    return { scenes, design }
  }

  const handleImportTemplate = React.useCallback(
    async (data: any) => {
      let template
      if (data.type === "GRAPHIC") {
        template = await loadGraphicTemplate(data)
      } else if (data.type === "PRESENTATION") {
        template = await loadPresentationTemplate(data)
      } else if (data.type === "VIDEO") {
        template = await loadVideoTemplate(data)
      }
      //   @ts-ignore
      setScenes(template.scenes)
      //   @ts-ignore
      setCurrentDesign(template.design)
    },
    [editor]
  )

  const handleLogout = () => {
    sessionStorage.removeItem("token")
    dispatch(
      setAuth({
        isAuthenticated: false,
        user: null,
        token: null,
      })
    )
    window.location.href = "/login"
  }

  useEffect(() => {
    if (editor) {
      if (!isOwner && isOwnerDirty) {
        fetch("/data/template.json").then((res) => {
          res.json().then(async (data) => {
            if (editor.scene.exportToJSON().layers.length <= 1) {
              handleImportTemplate(data)
              // editor.scene.importFromJSON(data)
              // const template = await loadGraphicTemplate(data)

              // setCurrentDesign(template.design)
            }
          })
        })
      }
    }
  }, [editor, isOwner, isOwnerDirty])

  let colorT = color
  if (transparentNav) {
    position = "fixed top-0 w-full"
    colorT = "text-white bg-transparent border-transparent shadow-none"
  }

  const router = ""
  const [isShowMobile, setIsShowMobile] = useState(false)

  const [isShowDropDown, setShowDropDown] = useState([...DROPDOWNLIST])

  const toggleDropdown = (index: any = false) => {
    const newDropdownList = [...DROPDOWNLIST]
    if (index !== false) newDropdownList[index] = true
    setShowDropDown(newDropdownList)
  }

  //navbar scroll when active state
  const [navbar, setNavbar] = useState(false)
  const [isAccountDropdown, setIsAccountDropdown] = useState(false)

  //navbar scroll changeBackground function
  const changeBackground = () => {
    if (window.scrollY <= 66) {
      setNavbar(true)
    } else {
      setNavbar(false)
    }
  }

  const [onNav, setOnNav] = useState(false)

  useEffect(() => {
    changeBackground()
    // adding the event when scroll change background
    window.addEventListener("scroll", changeBackground)
  })

  const [activeDropDown, setActiveDropDown] = useState({
    notification: false,
    create: false,
    help: false,
    chrome: false,
  })

  const toggleDropDown = (type: EDropdown) => {
    setActiveDropDown((prevState) => {
      const newState = Object.keys(prevState).reduce((acc, key) => {
        acc[key as EDropdown] = false
        return acc
      }, {} as { [key in EDropdown]: boolean })

      return {
        ...newState,
        [type]: !prevState[type],
      }
    })
  }

  return (
    <div
      className={` Montserrat ${isShowMobile ? color : ""} ${position} ${onNav ? "z-[1000]" : "z-20"} bg-[#bc4cf0] `}
    >
      {/* <TopBarAds /> */}
      <nav
        className="transition-colors px-2 sm:px-4 py-2"
        onMouseEnter={setOnNav.bind(this, true)}
        onMouseLeave={setOnNav.bind(this, false)}
      >
        <div className="flex flex-wrap justify-between lg:justify-start items-center relative">
          {isShowDropDown.some((e) => e === true) && (
            <div className="absolute left-0 right-0 h-52 -z-20" onMouseLeave={() => toggleDropdown()}></div>
          )}

          <div className="flex items-center mr-10">
            <img
              src={BrandLogo}
              width={100}
              height={100}
              // onClick={() => router.replace("/")}
              className="w-12 h-auto cursor-pointer"
              alt="logo"
            />
          </div>

          <div className="lg:hidden lg:order-2">
            <div className="hidden relative mr-3 lg:mr-0 lg:block">
              <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                <svg
                  className="w-5 h-5 text-gray-500"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </div>
              <input
                type="text"
                id="email-adress-icon"
                className="block p-2 pl-10 w-full text-gray-900 bg-gray-50 rounded-lg border lg:hidden border-gray-300 sm:text-sm focus:ring-secCol1-500 focus:border-secCol1-500"
                placeholder="Search..."
                onKeyPress={(e: any) => {
                  if (e.key === "Enter") {
                    window.location.href = `/page/search?q=${e.target.value}`
                  }
                }}
              />
            </div>
            <div className="flex items-center">
              <SearchComp />

              <NotificationsComp
                toggleDropDown={toggleDropDown}
                show={activeDropDown.notification || false}
                // @ts-ignore
                info={info}
                isMobile={true}
              />

              <Create
                isMobile={true}
                toggleDropDown={toggleDropDown}
                show={activeDropDown.create || false}
                transparentNav={true}
                setActiveDropDown={setActiveDropDown}
              />
              <Help
                isMobile={true}
                toggleDropDown={toggleDropDown}
                show={activeDropDown.help || false}
                transparentNav={true}
                setActiveDropDown={setActiveDropDown}
              />

              <Button
                data-collapse-toggle="mobile-menu-3"
                type="button"
                className="inline-flex text-white items-center p-2 ml-3 text-sm text-gray-500 rounded-lg  bg-white hover:bg-secCol1-50 focus:outline-none focus:ring-2 focus:ring-gray-200"
                aria-controls="mobile-menu-3"
                aria-expanded="false"
                onClick={() => setIsShowMobile(!isShowMobile)}
              >
                <span className="sr-only">Open main menu</span>
                <MenuIcon className="text-white" />
              </Button>
            </div>
          </div>
          {NavItemComponent(
            isShowMobile,
            toggleDropdown,
            isShowDropDown,
            staticPage,
            info,
            router,
            true,
            navbar,
            handleLogout
          )}
          {!staticPage && (
            <div className="lg:order-4   lg:ml-auto hidden lg:block">
              {isAuthenticated ? (
                <div className="text-xs">
                  <div
                    className={`relative  inline-block  w-full`}
                    // onMouseEnter={() => setIsAccountDropdown(true)}
                    // onMouseLeave={() => setIsAccountDropdown(false)}
                  >
                    <div className=" flex w-full gap-2 items-center mt-0.5 h-[100%]">
                      <div className="mr-4">
                        <DesignTitle />
                      </div>
                      <SearchComp />
                      <ChromeWebstore
                        // @ts-ignore
                        toggleDropDown={toggleDropDown}
                        show={activeDropDown.chrome || false}
                        transparentNav={transparentNav && navbar}
                        setActiveDropDown={setActiveDropDown}
                      />
                      <Create
                        isMobile={false}
                        toggleDropDown={toggleDropDown}
                        show={activeDropDown.create || false}
                        transparentNav={navbar && transparentNav}
                        setActiveDropDown={setActiveDropDown}
                      />
                      <Help
                        isMobile={false}
                        toggleDropDown={toggleDropDown}
                        show={activeDropDown.help || false}
                        transparentNav={transparentNav && navbar}
                        setActiveDropDown={setActiveDropDown}
                      />

                      {/* @ts-ignore */}
                      <NotificationsComp
                        toggleDropDown={toggleDropDown}
                        show={activeDropDown.notification || false}
                        // @ts-ignore
                        info={info}
                        token={""}
                        transparentNav={transparentNav && navbar}
                      />

                      <div className="flex items-center">
                        <div className=" flex items-center  h-[100%]">
                          <a
                            href={MAIN_DOMAIN + "/auth/me"}
                            onMouseEnter={() => setIsAccountDropdown(!isAccountDropdown)}
                            className={`cursor-pointer flex items-center  gap-1 text-sm pr-4 pl-2 border-b border-secCol1-50 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 ${
                              navbar ? "lg:hover:text-[#d9d9d9]" : ""
                            }`}
                            onMouseLeave={() => setIsAccountDropdown(false)}
                          >
                            {/* <PersonIcon fontSize={"small"} />
                        My Account */}
                            <AccountIcon
                              email={info?.email ? info?.email : ""}
                              src={info?.image ? info?.image : ""}
                              user_id={info?._id ? info?._id : ""}
                            />
                            {isAccountDropdown ? (
                              <ArrowDropUp fontSize={"small"} />
                            ) : (
                              <ArrowDropDown fontSize={"small"} />
                            )}
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className={`mt-0`} />
                    {/* a div just to act as a safe boundary */}
                    <div
                      onMouseEnter={() => {
                        isAccountDropdown ? setIsAccountDropdown(true) : null
                      }}
                      className="absolute h-5 w-32 "
                      style={{ top: "30px", right: "20px" }}
                    />

                    <div hidden={!isAccountDropdown} className="">
                      <div
                        style={{
                          boxShadow: "2px 2px 20px 2px rgba(0, 0, 0, 0.3), 0 4px 6px -2px rgba(0, 0, 0, 0.2)",
                        }}
                        className={`animate__animated animate__fadeIn animate__faster rounded-lg 
fixed top-[6.5%] right-[1%] transform -translate-x-1/2  w-[280px]  max-w-[75w] z-50  list-none bg-white divide-y divide-secCol1-50 lg:shadow`}
                        // onMouseLeave={() => toggleDropdown()}
                        onMouseLeave={() => setIsAccountDropdown(false)}
                      >
                        {/* @ts-ignore */}
                        <AccountDropdown info={info} handleLogOutAll={handleLogout} />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="flex gap-2 items-center">
                  <a href={MAIN_DOMAIN + `/auth/register`}>
                    <button
                      style={{
                        textTransform: "none",
                        border: !navbar ? "1px solid purple" : "1px solid white",
                      }}
                      className={`text-sm ${navbar ? "text-white" : "text-[#9107a7]"} noBG br-16 py-1.5 noBg p-2`}
                    >
                      Create Account
                    </button>
                  </a>

                  <div className="">
                    <button
                      style={{
                        textTransform: "none",
                        border: "none",
                      }}
                      className={`text-sm ${
                        !navbar ? "" : ""
                      } p-2 my-1    text-transparent cursor-pointer themeGradient  br-16 np `}
                      // onClick={() => signIn()}
                    >
                      Login
                    </button>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </nav>
    </div>
  )
}

export default Navbar
