import React from "react"
import { useState } from "react"
import NotificationsIcon from "@mui/icons-material/Notifications"
import { Typography } from "@mui/material"
import { useEffect } from "react"
import DeleteIcon from "@mui/icons-material/Delete"
import { formatDistanceToNow } from "date-fns"
import Badge from "@mui/material/Badge"
import RefreshIcon from "@mui/icons-material/Refresh"
import { EDropdown } from "~/views/DesignEditor/components/Navbar/Navbar"

type TUser = {
  verified: boolean
  type: number
  source: string
  is_blocked: boolean
  _id: string
  email: string
  createdAt: string
  updatedAt: string
  __v: number
  id: string
  has_past_due: boolean
}

interface INotification {
  id: string
  user_id: string
  title: string
  message: string
  type: string
  category: string
  icon: string
  link: string
  is_read: boolean
  is_deleted: boolean
  created_at: string
  notification_id: number
}

interface INotificationsResponse {
  notifications: INotification[]
  current_page: number
  has_more: boolean
  next_page: number
  total_count: number
}

interface INotificationProps {
  info: TUser
  show: boolean
  toggleDropDown: (type: any) => void
  isMobile?: boolean
  token?: string
  transparentNav?: boolean
}

const Notifications: React.FC<INotificationProps> = ({
  info,
  show,
  toggleDropDown,
  isMobile,
  token = "",
  transparentNav,
}) => {
  const [notifications, setNotifications] = useState<INotification[]>([])
  const [notificationCount, setNotificationCount] = useState(0)
  const [isOpen, setIsOpen] = useState(false)
  const [page, setPage] = useState(1)
  const [hasMore, setHasMore] = useState(true)
  const [loading, setLoading] = useState(false)

  const handleClick = () => {
    setIsOpen(!isOpen)
    toggleDropDown(EDropdown.notification)
  }

  const handleClose = () => {
    setIsOpen(false)
    toggleDropDown(EDropdown.notification)
  }

  const base = "https://stingray-app-tx5jj.ondigitalocean.app"

  const getNotifications = async () => {
    try {
      setLoading(true)
      const response = await fetch(base + `/api/notifications?page=${page}&page_size=5&user_id=${info._id}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      if (!response.ok) {
        throw new Error("Failed to fetch notifications")
      }

      const data: INotificationsResponse = await response.json()

      setHasMore(data.has_more)
      // @ts-ignore
      setNotifications((prevNotifications) => {
        try {
          const updatedNotifications = page === 1 ? data.notifications : [...prevNotifications, ...data.notifications]

          // Sort notifications by created_at in descending order
          return updatedNotifications.sort(
            (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
          )
        } catch (error) {
          console.error("Error updating notifications:", error)
          return prevNotifications // Return the previous state in case of an error
        }
      })
      setNotificationCount(notifications.length)
    } catch (error) {
      console.error("Error fetching notifications:", error)
    } finally {
      setLoading(false)
    }
  }

  //  useEffect(() => {
  //    const interval = setInterval(() => {
  //      getNotifications();
  //    }, 60000); // Check for new notifications every 60 seconds

  //    return () => clearInterval(interval);
  //  }, []);

  useEffect(() => {
    getNotifications()
  }, [page])

  const DeleteNotification = async (id: number) => {
    try {
      const response = await fetch(base + `/api/notifications?notification_id=${id}&user_id=${info._id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      if (!response.ok) {
        throw new Error("Failed to delete notification")
      }

      setNotifications((prevNotifications) =>
        prevNotifications.filter((notification) => notification.notification_id !== id)
      )
    } catch (error) {
      console.error("Error deleting notification:", error)
    }
  }

  async function MarkAsRead(id: number) {
    try {
      const response = await fetch(base + `/api/notifications/mark-as-read?notification_id=${id}&user_id=${info._id}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      if (!response.ok) {
        throw new Error("Failed to mark notification as read")
      }
    } catch (error) {
      console.error("Error marking notification as read:", error)
    }
  }

  function HandleLoadMore() {
    setPage(page + 1)
  }

  function Refresh() {
    setNotifications([])
    setHasMore(true)
    setPage(1)
    getNotifications()
  }
  return (
    <div className="relative mx-2 inline-block text-left ">
      <div
        onClick={handleClick}
        onMouseEnter={() => handleClick()}
        className=""
        style={{
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "center",
          width: "40px",
          border: "1px solid",
          borderColor: transparentNav ? "white" : "#7449fb",
          height: "40px",
          borderRadius: "50%",
          backgroundColor: "white",
          cursor: "pointer",
          // position: "relative",
        }}
      >
        <Badge
          badgeContent={notificationCount}
          color="primary"
          overlap="circular"
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <NotificationsIcon className="text-black" />
        </Badge>
      </div>
      {show && (
        <div
          style={{
            border: "1px solid #ccc",
            // left: "50%",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Example box shadow
          }}
          className={`animate__animated animate__fadeIn animate__faster rounded-lg 
fixed mt-2  right-[10%] p-2 max-w-[75w] w-[350px] z-50  list-none bg-white divide-y divide-secCol1-50 lg:shadow`}
          onMouseLeave={handleClose}
        >
          <div className="">
            <Typography variant="body1" className="px-2 text-black font-bold mb-2">
              {" "}
              Notifications ({notificationCount})
            </Typography>
            <RefreshIcon onClick={() => Refresh()} className="text-black cursor-pointer absolute right-2 mr-3 top-2" />
          </div>
          {notifications.length > 0 ? (
            notifications.map((notification, index) => (
              <>
                {/* @ts-ignore */}
                <NotificationCard
                  key={index}
                  index={index}
                  Notification={notification}
                  user_id={info._id}
                  markAsRead={MarkAsRead}
                  deleteNotification={DeleteNotification}
                />
              </>
            ))
          ) : (
            <Typography variant="body2" className="text-[14px] pl-3">
              No notifications
            </Typography>
          )}
          {hasMore && (
            <div
              onClick={() => HandleLoadMore()}
              className="text-center text-blue-500 cursor-pointer font-bold font-sans"
            >
              {loading ? "Loading..." : "Load More"}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default Notifications

interface INotificationProps {
  Notification: INotification
  index: number
  user_id: string
  deleteNotification: (id: number) => void
  markAsRead: (id: number) => void
}

export const NotificationCard: React.FC<INotificationProps> = ({
  Notification,
  index,
  user_id,
  deleteNotification,
  markAsRead,
}) => {
  const notificationDate = new Date(Notification.created_at)
  const displayDate = isNaN(notificationDate.getTime()) ? new Date() : notificationDate

  async function handleClick() {
    markAsRead(Notification.notification_id)
    window.open(Notification.link, "_blank")
  }

  return (
    <div
      style={{
        borderTop: index != 0 ? "1px solid #ccc" : "none",
      }}
      className={`p-1 px-2  border-gray-300 ${
        Notification.is_read ? "bg-gray-300" : "bg-white"
      } hover:bg-gray-300 transition-colors rounded-lg cursor-pointer duration-200`}
    >
      <div className="flex items-center justify-between">
        <div onClick={() => handleClick()} className="flex flex-col">
          <Typography className="font-medium text-black" variant="body2">
            {Notification.title}
          </Typography>
          <Typography variant="body2" className=" text-[#5d5d5d] font-medium">
            {Notification.message}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            {formatDistanceToNow(displayDate)} ago
          </Typography>
          {}
        </div>
        <div className="flex justify-end mt-2">
          <DeleteIcon className="text-black" onClick={() => deleteNotification(Notification.notification_id)} />
        </div>
      </div>
    </div>
  )
}
