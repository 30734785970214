import React from "react"
import { Menu as MenuIcon, Logout as LogoutIcon, AccountCircle as AccountCircleIcon } from "@mui/icons-material"
import { Link } from "react-router-dom"
import TUser from "~/models/user"
import { CiUser } from "react-icons/ci"
import { FaRegUser } from "react-icons/fa6"
import { FiLogOut } from "react-icons/fi"
import Avatar from "@mui/material/Avatar"
import PersonIcon from "@mui/icons-material/Person"
import { FaCrown } from "react-icons/fa"
import { SiPlanetscale } from "react-icons/si"
import { FaRegRectangleList } from "react-icons/fa6"
import BookOutlinedIcon from "@mui/icons-material/BookOutlined"
import Create from "./Create"

interface AccountDropdownProps {
  // isAccountDropdown: boolean
  // setIsAccountDropdown: React.Dispatch<React.SetStateAction<boolean>>
  handleLogOutAll: () => void
  info: TUser
}

const getInitial = (email: string) => {
  return email.charAt(0).toUpperCase()
}

const isOwned = (featuresOwned: any, id: any) => {
  return featuresOwned?.find((item: any) => item.feature_id._id === id)
}

const mainSite = "https://selfpublishingtitans.com"

const AccountDropdown: React.FC<AccountDropdownProps> = ({
  // isAccountDropdown,
  // setIsAccountDropdown,
  handleLogOutAll,
  info,
}) => {
  const email = info?.email ? info?.email : ""
  //   @ts-ignore
  const src = info?.image ? info?.image : ""
  const user_id = info?._id ? info?._id : ""

  console.log("account dropdown info", info)

  function NeedToUpgrade(): {
    upgradeTo: string
    currentTier: string
    show: boolean
  } {
    try {
      if (!info || !info.features || !info.features.simple) {
        console.error("Invalid info structure")
        return {
          upgradeTo: "0",
          currentTier: "",
          show: false,
        }
      }

      const features = info.features.simple

      if (isOwned(features, "64562adce2210da37f2bdb2a")) {
        return {
          upgradeTo: "Pro Max",
          currentTier: "Pro",
          show: true,
        }
      }
      if (isOwned(features, "6516aecf8a69c334783b3c27")) {
        return {
          upgradeTo: "Supreme",
          currentTier: "Pro Max",
          show: true,
        }
      }

      if (isOwned(features, "655ee43f1727b2465e13079b")) {
        return {
          upgradeTo: "Supreme",
          currentTier: "Supreme",
          show: false,
        }
      }

      return {
        upgradeTo: "Pro",
        currentTier: "Free",
        show: true,
      }
    } catch (error) {
      console.error("Error in NeedToUpgrade function:", error)
      return {
        upgradeTo: "",
        currentTier: "",
        show: false,
      }
    }
  }

  // const isUpgrade = NeedToUpgrade()

  console.log("rendering account dropdown")

  return (
    <>
      {/* <div
        onMouseEnter={() => {
          isAccountDropdown ? setIsAccountDropdown(true) : null
        }}
        // onMouseLeave={() => setIsAccountDropdown(false)}
        className="absolute h-5 w-32 "
        style={{ top: "30px", right: "20px" }}
      />
      <div
        hidden={!isAccountDropdown}
        onMouseLeave={() => setIsAccountDropdown(false)}
        onMouseEnter={() => setIsAccountDropdown(true)}
        className="absolute right-0 border-2 z-10 mt-0 origin-top-right rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
        role="menu"
        style={{ border: "1px solid #cccccc", width: "280px" }}
        aria-orientation="vertical"
        aria-labelledby="menu-button"
      > */}
      <div
        style={{
          borderRadius: "10px",
          border: "1px solid #ccc",
        }}
        className="text-black  m-1 p-2 flex gap-2 items-center"
      >
        <div className="flex gap-2 justify-between w-full items-center">
          <div className="flex gap-2 items-center">
            {src ? (
              <Avatar src={src} style={{ width: 40, height: 40 }} />
            ) : email ? (
              <Avatar style={{ width: 40, height: 40 }}>{getInitial(email)}</Avatar>
            ) : (
              <Avatar style={{ width: 40, height: 40 }}>
                <PersonIcon />
              </Avatar>
            )}
            <div className="flex flex-col">
              {/* <div>username</div> */}
              <div
                style={{
                  width: "150px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {email}
              </div>
            </div>
          </div>
          {/* {isUpgrade.currentTier && (
              <div
                style={{
                  color: "#7449fb",
                  borderRadius: "16px",
                  border: "1.5px solid #7449fb",
                }}
                className="p-1 px-3"
              >
                {isUpgrade.currentTier && <span>{isUpgrade.currentTier}</span>}
              </div>
            )} */}
        </div>
      </div>
      <div className="p-3">
        <div className="text-black flex-col gap-3 flex text-gray-500 font-sans">
          {/* <Link href={"/profile"} className="flex gap-4 items-center">
              <FaRegUser className="" fontSize={16} />
              <span className="text-black text-gray-500 text-[15px]">
                Profile
              </span>
            </Link> */}
          <Link to={mainSite + "/auth/me"} className="flex gap-4 items-center">
            <FaRegRectangleList className="" fontSize={16} />
            <span className="text-black text-gray-500 text-[15px]">Account</span>
          </Link>
          <Link to={mainSite + "/book-planner"} className="flex gap-4 items-center">
            {/* @ */}
            <BookOutlinedIcon className="" style={{ fontSize: "16px" }} />
            <span className="text-black text-gray-500 text-[15px]">Notes & Planning</span>
          </Link>
          {/* <Link href={"/auth/me"} className="flex gap-4 items-center">
              <SiPlanetscale className="" fontSize={16} />
              <span className="text-black text-gray-500 text-[15px]">
                Subscriptions
              </span>
            </Link> */}
        </div>
      </div>
      {/* <Upgrade info={info} isUpgrade={isUpgrade} /> */}
      {/* {!isUpgrade.show && <hr />} */}
      {<hr />}
      <div className="p-3">
        <div
          onClick={() => handleLogOutAll()}
          className="text-black cursor-pointer flex-col gap-3 flex text-gray-500 font-sans"
        >
          <div className="flex gap-4 items-center">
            <FiLogOut className="" fontSize={16} />
            <span className="text-black text-gray-500 text-[15px]">Logout</span>
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  )
}

export default AccountDropdown

interface IUpgradeProps {
  info: TUser
  isUpgrade: {
    upgradeTo: string
    currentTier: string
    show: boolean
  }
}
function Upgrade({ info, isUpgrade }: IUpgradeProps) {
  if (!isUpgrade.show) {
    return null
  }

  return (
    <Link
      to={"/pricing"}
      style={{
        borderRadius: "10px",
      }}
      className="p-2 py-3 m-1 text-[15px] text-black flex justify-center items-center bg-[#7449fb] text-white rounded-xl font-sans border border-white font-bold shadow-sm"
    >
      Upgrade <FaCrown className="ml-2" />
    </Link>
  )
}
