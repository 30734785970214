import React, { useRef, useState, useEffect } from "react"
import AddIcon from "@mui/icons-material/Add"
import PhotoAlbumIcon from "@mui/icons-material/PhotoAlbum"
import ImageIcon from "@mui/icons-material/Image"
import ExtensionIcon from "@mui/icons-material/Extension"
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks"
import { Link } from "react-router-dom"
import { Typography } from "@mui/material"
import SearchIcon from "@mui/icons-material/Search"
import ViewListIcon from "@mui/icons-material/ViewList"
import Filter7Icon from "@mui/icons-material/Filter7"
import YoutubeSearchedForIcon from "@mui/icons-material/YoutubeSearchedFor"
import AccountCircleIcon from "@mui/icons-material/AccountCircle"
import { EDropdown } from "./Navbar"
import BrushIcon from "@mui/icons-material/Brush"
import StarsRoundedIcon from "@mui/icons-material/StarsRounded"
import ForumRoundedIcon from "@mui/icons-material/ForumRounded"
import CalculateIcon from "@mui/icons-material/Calculate"
import PriceCheckIcon from "@mui/icons-material/PriceCheck"
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner"
import ManageSearchIcon from "@mui/icons-material/ManageSearch"
import SsidChartIcon from "@mui/icons-material/SsidChart"
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted"
import TitleIcon from "@mui/icons-material/Title"
import ViewHeadlineIcon from "@mui/icons-material/ViewHeadline"
import CopyrightIcon from "@mui/icons-material/Copyright"
import ChecklistIcon from "@mui/icons-material/Checklist"
import TranslateIcon from "@mui/icons-material/Translate"
import PriceChangeIcon from "@mui/icons-material/PriceChange"
import DoneAllIcon from "@mui/icons-material/DoneAll"
import YouTubeIcon from "@mui/icons-material/YouTube"
import SubtitlesOutlinedIcon from "@mui/icons-material/SubtitlesOutlined"
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined"
import GavelOutlinedIcon from "@mui/icons-material/GavelOutlined"
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined"
import SpellcheckOutlinedIcon from "@mui/icons-material/SpellcheckOutlined"
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined"
import QrCode2OutlinedIcon from "@mui/icons-material/QrCode2Outlined"
import CloudOutlinedIcon from "@mui/icons-material/CloudOutlined"
import AdsClickOutlinedIcon from "@mui/icons-material/AdsClickOutlined"
import BallotIcon from "@mui/icons-material/Ballot"
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined"
import CompareOutlinedIcon from "@mui/icons-material/CompareOutlined"
import PriceChangeOutlinedIcon from "@mui/icons-material/PriceChangeOutlined"
import MenuBookIcon from "@mui/icons-material/MenuBook"
import { CategoryOutlined } from "@mui/icons-material"
import { Create as CreateIcon } from "@mui/icons-material"
import { FaAmilia } from "react-icons/fa6"

type TOption = {
  name: string
  Icon: React.ElementType
  tag?: string
  link: string

  //   link:string
}

function EditNoteicon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="mr-4"
      height="35px"
      viewBox="0 -960 960 960"
      width="35px"
      fill="#00000"
    >
      <path d="M480-80q-139-35-229.5-159.5T160-516v-244l320-120 320 120v200h-80v-145l-240-90-240 90v189q0 121 68 220t172 132q26-8 49.5-20.5T576-214l56 56q-33 27-71.5 47T480-80Zm360 0q-17 0-28.5-11.5T800-120q0-17 11.5-28.5T840-160q17 0 28.5 11.5T880-120q0 17-11.5 28.5T840-80Zm-40-160v-240h80v240h-80ZM480-480Zm0 80q33 0 56.5-23.5T560-480q0-33-23.5-56.5T480-560q-33 0-56.5 23.5T400-480q0 33 23.5 56.5T480-400Zm0 80q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 22-5.5 42.5T618-398l119 118-57 57-120-119q-18 11-38.5 16.5T480-320Z" />
    </svg>
  )
}
function TextCompare() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="mr-4"
      height="24px"
      viewBox="0 -960 960 960"
      width="24px"
      fill="#00000"
    >
      <path d="M400-40v-80H200q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h200v-80h80v880h-80ZM200-200h200v-80H280v-80h120v-80H280v-80h120v-80H280v-80h120v-80H200v560Zm360-560v-80h200q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H560v-80h200v-560H560Zm0 320v-80h120v80H560Zm0-160v-80h120v80H560ZM400-480Z" />
    </svg>
  )
}

function TwoPages() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="34px"
      className="mr-4"
      viewBox="0 -960 960 960"
      width="34px"
      fill="#00000"
    >
      <path d="M160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160Zm0-80h280v-480H160v480Zm360 0h280v-480H520v480Zm-320-80h200v-80H200v80Zm0-120h200v-80H200v80Zm0-120h200v-80H200v80Zm360 240h200v-80H560v80Zm0-120h200v-80H560v80Zm0-120h200v-80H560v80ZM440-240v-480 480Z" />
    </svg>
  )
}

const CreateOptions: TOption[] = [
  {
    name: "Book Cover",
    Icon: PhotoAlbumIcon,
    link: "https://selfpublishingtitans.com/book-cover-creator",
  },
  {
    name: "A+ Content",
    Icon: ImageIcon,
    link: "https://selfpublishingtitans.com/A+-content-template-generator",
  },
  {
    name: "Write Book",
    Icon: MenuBookIcon,
    link: "https://author.selfpublishingtitans.com",
  },
  {
    name: "Puzzle Pages",
    Icon: ExtensionIcon,
    link: "https://books.selfpublishingtitans.com/Puzzle-Maker-Software",
  },
  {
    name: "Low Content Pages",
    Icon: LibraryBooksIcon,
    link: "https://books.selfpublishingtitans.com/FREE-No-Content-Book-Creator-Software/start-now?simple=true",
  },
  {
    name: "Coloring Book",
    Icon: BrushIcon,
    link: "https://editor.selfpublishingtitans.com",
  },
  {
    name: "Digital Titans Designer",
    Icon: FaAmilia,
    link: "https://design.selfpublishingtitans.com/",
  },
]

const ResearchOptions: TOption[] = [
  {
    name: "Titans Pro",
    tag: "Search Suggestions",
    Icon: SearchIcon,
    link: "https://chromewebstore.google.com/detail/titans-pro-amazon-kdp-key/mmdamlknnafgffhlobhlmiljonijdnid",
  },
  {
    name: "Deep View",
    tag: "Niche Analysis",
    Icon: BallotIcon,
    link: "https://selfpublishingtitans.com/titans-deep-view",
  },
  {
    name: "7 Backend Keywords",
    tag: "Book Listing SEO",
    Icon: Filter7Icon,
    link: "https://selfpublishingtitans.com/7-backend-keywords-tool-amazon-kdp",
  },
  {
    name: "Retro View",
    tag: "Competetior Analysis",
    Icon: YoutubeSearchedForIcon,
    link: "https://selfpublishingtitans.com/titans-retro-vision",
  },
  {
    name: "Quick View",
    tag: "Quick Analysis",
    Icon: VerifiedOutlinedIcon,
    link: "https://chromewebstore.google.com/detail/titans-quick-view-amazon/eefljgmhgaidffapnppcmmafobefjece",
  },
]

const GeneralTools: TOption[] = [
  {
    name: "KDP BSR Sales Calculator",
    tag: "Estimate anyone's book sales",
    link: "https://selfpublishingtitans.com/tools/KDP-BSR-Sales-Calculator",
    Icon: PriceChangeIcon,
  },
  {
    name: "ACOS Royalty Calculator",
    tag: "Calculate ad campaign profitability.",
    link: "https://selfpublishingtitans.com/tools/KDP-Royalty-Calculator",
    Icon: AdsClickOutlinedIcon,
  },
  {
    name: "KDP Category Finder",
    tag: "Find the best categories for your book and improve your SEO.",
    link: "https://selfpublishingtitans.com/tools/KDP-Kindle-Category-Finder",
    Icon: CategoryOutlined,
  },
  {
    name: "QR Code Generator",
    tag: "Create QR codes for your books",
    link: "https://selfpublishingtitans.com/tools/QR-Code-Generator-for-KDP-Books",
    Icon: QrCode2OutlinedIcon,
  },
  {
    name: "Pen Name Generator",
    tag: "Create pen names for your books",
    link: "https://selfpublishingtitans.com/tools/Pen-Name-Generator",
    Icon: CreateIcon,
  },
  {
    name: "Word Cloud Generator",
    tag: "View most used words and keywords",
    link: "https://selfpublishingtitans.com/word-cloud",
    Icon: CloudOutlinedIcon,
  },
  {
    name: "Keyword Tracker",
    tag: "Track Keyword ranking for any product",
    link: "https://selfpublishingtitans.com/titans-keyword-tracker",
    Icon: TextCompare,
  },
  {
    name: "ASIN Tracker",
    tag: "Monitor any products performance",
    link: "https://selfpublishingtitans.com/titans-asin-tracker",
    Icon: CompareOutlinedIcon,
  },
  {
    name: "KDP Cover Template Generator",
    tag: "Create KDP Cover Templates",
    link: "https://selfpublishingtitans.com/kdp-cover-template-generator",
    Icon: TwoPages,
  },
]

const LearningTools: TOption[] = [
  {
    name: "Youtube Tutorials",
    Icon: YouTubeIcon,
    link: "https://www.youtube.com/@SelfPublishingTitans",
  },
  {
    name: "KDP Masterclass",
    Icon: SchoolOutlinedIcon,
    link: "https://www.selfpublishingtitans.com/masterclass",
  },
]

const BookListingTools: TOption[] = [
  {
    name: "KDP Title Creator",
    Icon: SubtitlesOutlinedIcon,
    link: "https://selfpublishingtitans.com/kdp-title-creator",
  },
  {
    name: "KDP Description Generator",
    Icon: EditNoteOutlinedIcon,
    link: "https://selfpublishingtitans.com/kdp-description-creator",
  },
  {
    name: "KDP Trademark Violation Checker",
    Icon: EditNoteicon,
    link: "https://selfpublishingtitans.com/kdp-trademark-checker",
  },
  {
    name: "KDP Guidelines Checker",
    Icon: GavelOutlinedIcon,
    link: "https://selfpublishingtitans.com/kdp-guidelines-checker",
  },
  {
    name: "KDP Book Data Translator",
    Icon: TranslateIcon,
    link: "https://selfpublishingtitans.com/kdp-bookdata-translator",
  },
  {
    name: "Book Price Suggestion Tool",
    Icon: AttachMoneyOutlinedIcon,
    link: "https://www.selfpublishingtitans.com/book-price",
  },
  {
    link: "https://www.selfpublishingtitans.com/grammar-check",
    name: "Grammar Checker",
    Icon: SpellcheckOutlinedIcon,
  },
]

interface ICreateProps {
  show: boolean
  transparentNav?: boolean
  toggleDropDown: (type: EDropdown) => void
  isMobile?: boolean
  setActiveDropDown: (state: any) => void
}

const Create: React.FC<ICreateProps> = ({ show, transparentNav, toggleDropDown, isMobile, setActiveDropDown }) => {
  const divRef = useRef<HTMLDivElement>(null)

  const handleClick = () => {
    setActiveDropDown({
      create: true,
      help: false,
    })
    // toggleDropDown(EDropdown.help);
    // console.log("handling click");
  }

  const handleClose = () => {
    // setIsOpen(false);
    // toggleDropDown(EDropdown.help);
    // console.log("closed");
    setActiveDropDown({
      create: false,
      help: false,
    })
  }

  // const handleClickOutside = (event: MouseEvent) => {
  //   if (divRef.current && !divRef.current.contains(event.target as Node)) {
  //     setActiveDropDown({
  //       create: false,
  //       help: false,
  //     });
  //   }
  // };

  // useEffect(() => {
  //   if (show) {
  //     document.addEventListener("mousedown", handleClickOutside);
  //   } else {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   }

  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [show]);

  return (
    <div ref={divRef} className="relative text-black  mx-2 inline-block text-left">
      <button
        onClick={handleClick}
        onMouseEnter={handleClick}
        onMouseLeave={() => {
          setActiveDropDown({
            create: false,
            help: false,
          })
        }}
        style={{
          border: "1px solid",
          borderColor: !isMobile ? "white" : "#7449fb",
        }}
        className="flex px-2 py-1 items-center cursor-pointer justify-center w-full rounded-xl border border-white bg-transparent shadow-sm"
      >
        <Typography
          style={{
            fontWeight: "500",
          }}
          variant="body1"
          className={`${!isMobile ? "text-white" : "text-black"} cursor-pointer pr-2`}
        >
          Start
        </Typography>
        <AddIcon fontSize="small" className={` text-[16px] ${!isMobile ? "text-white" : "text-black"}`} />
      </button>

      <div
        onMouseEnter={() => {
          if (true) {
            setActiveDropDown({
              create: true,
              help: false,
            })
          }
        }}
        className="absolute top-[20px] h-5 w-full"
      ></div>

      {show && (
        <div
          style={{
            border: "1px solid #ccc",
            // transform: isMobile ? "translateX(-50%)" : "translateX(-70%)",
          }}
          className={`animate__animated animate__fadeIn animate__faster rounded-lg 
fixed mt-2  right-[10%] max-w-[75w] w-[650px] z-50  list-none bg-white divide-y divide-secCol1-50 lg:shadow`}
          onMouseLeave={handleClose}
        >
          <div className="py-1 p-3" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            <div className="grid grid-cols-1 w-full ">
              <div className="">
                <Typography className="text-black mb-1 mt-1 font-bold" variant={"body1"}>
                  Create
                </Typography>
                <div className="w-full grid grid-cols-4">
                  {CreateOptions.map((option, index) => (
                    <a
                      key={index}
                      href={option.link}
                      target="__blank"
                      className="flex items-center rounded-lg  py-1.5 text-sm text-gray-700 hover:bg-[#c3abff] px-1 hover:text-gray-900"
                      role="menuitem"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <option.Icon className="mr-4" />
                      <Typography variant="body2" className="font-medium text-black">
                        {option.name}
                      </Typography>{" "}
                    </a>
                  ))}
                </div>
              </div>
              <Typography className="text-black mb-1 mt-4 font-bold" variant={"body1"}>
                Research
              </Typography>
              <div className="w-full grid grid-cols-3">
                {ResearchOptions.map((option, index) => (
                  <a
                    key={index}
                    target="__blank"
                    href={option.link}
                    className="flex items-center  py-1.5 text-sm text-gray-700 rounded-lg hover:bg-[#c3abff] px-1 hover:text-gray-900"
                    role="menuitem"
                  >
                    <option.Icon className="mr-4" />
                    <div className="">
                      <Typography variant="body2" className="font-medium text-black">
                        {option.name}
                      </Typography>
                      <Typography variant="body2" className="text-[12px]">
                        {option.tag}
                      </Typography>
                    </div>
                  </a>
                ))}
              </div>

              <div className="">
                <Typography className="text-black mb-1 mt-4 font-bold" variant={"body1"}>
                  Book Listing
                </Typography>
                <div className="w-full grid grid-cols-3">
                  {BookListingTools.map((option, index) => (
                    <a
                      key={index}
                      href={option.link}
                      target="__blank"
                      className="flex items-center rounded-lg  py-1.5 text-sm text-gray-700 hover:bg-[#c3abff] px-1 hover:text-gray-900"
                      role="menuitem"
                    >
                      <option.Icon className="mr-4" />
                      <Typography variant="body2" className="font-medium text-black">
                        {option.name}
                      </Typography>{" "}
                    </a>
                  ))}
                </div>
              </div>
              <div className="">
                <Typography className="text-black mb-1 mt-4 font-bold" variant={"body1"}>
                  General
                </Typography>
                <div className="w-full grid grid-cols-3">
                  {GeneralTools.map((option, index) => (
                    <a
                      key={index}
                      href={option.link}
                      target="__blank"
                      className="flex items-center rounded-lg  py-1.5 text-sm text-gray-700 hover:bg-[#c3abff] px-1 hover:text-gray-900"
                      role="menuitem"
                    >
                      <option.Icon className="mr-4" />
                      <Typography variant="body2" className="font-medium text-black">
                        {option.name}
                      </Typography>{" "}
                    </a>
                  ))}
                </div>
              </div>
              <div className="">
                <Typography className="text-black mb-1 mt-4 font-bold" variant={"body1"}>
                  Learn
                </Typography>
                <div className="w-full grid grid-cols-3">
                  {LearningTools.map((option, index) => (
                    <a
                      key={index}
                      href={option.link}
                      target="__blank"
                      className="flex items-center rounded-lg  py-1.5 text-sm text-gray-700 hover:bg-[#c3abff] px-1 hover:text-gray-900"
                      role="menuitem"
                    >
                      <option.Icon className="mr-4" />
                      <Typography variant="body2" className="font-medium text-black">
                        {option.name}
                      </Typography>{" "}
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Create
