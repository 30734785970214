import React, { useRef, useState, useEffect } from "react"
import { Typography } from "@mui/material"
import { EDropdown } from "./Navbar"

import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined"
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined"
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined"
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined"
import YouTubeIcon from "@mui/icons-material/YouTube"
import BugReportIcon from "@mui/icons-material/BugReport"
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos"
import MessageIcon from "@mui/icons-material/Message"
import { Group } from "@mui/icons-material"

interface ICreateProps {
  show: boolean
  transparentNav?: boolean
  toggleDropDown: (type: EDropdown) => void
  isMobile?: boolean
  setActiveDropDown: (state: any) => void
}

const Create: React.FC<ICreateProps> = ({ show, transparentNav, toggleDropDown, isMobile, setActiveDropDown }) => {
  const divRef = useRef<HTMLDivElement>(null)

  const handleClick = () => {
    setActiveDropDown({
      create: false,
      help: true,
    })
    // toggleDropDown(EDropdown.help);
    // console.log("handling click");
  }

  const handleClose = () => {
    // setIsOpen(false);
    toggleDropDown(EDropdown.help)
    console.log("closed")
  }

  // const handleClickOutside = (event: MouseEvent) => {
  //   if (divRef.current && !divRef.current.contains(event.target as Node)) {
  //     setActiveDropDown({
  //       create: false,
  //       help: false,
  //     });
  //   }
  // };

  // useEffect(() => {
  //   if (show) {
  //     document.addEventListener("mousedown", handleClickOutside);
  //   } else {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   }

  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [show]);

  return (
    <div ref={divRef} className="relative text-black  mx-2 inline-block text-left">
      <button
        onClick={handleClick}
        onMouseEnter={handleClick}
        onMouseLeave={() => {
          setActiveDropDown({
            create: false,
            help: false,
          })
        }}
        style={{
          border: "1px solid",
          borderColor: !isMobile ? "white" : "#7449fb",
        }}
        className="flex px-2 py-1 items-center cursor-pointer justify-center w-full rounded-xl border border-white bg-transparent shadow-sm"
      >
        <Typography
          style={{
            fontWeight: "500",
          }}
          variant="body1"
          className={`${!isMobile ? "text-white" : "text-black"} pr-2 cursor-pointer`}
        >
          Help
        </Typography>
        <HelpOutlineOutlinedIcon
          fontSize={"small"}
          className={`ml text-[12px] ${!isMobile ? "text-white" : "text-black"}`}
        />
      </button>

      <div
        onMouseEnter={() => {
          if (true) {
            setActiveDropDown({
              create: false,
              help: true,
            })
          }
        }}
        className="absolute top-[20px] h-5 w-full"
      ></div>

      {show && (
        <div
          onMouseLeave={() => {
            setActiveDropDown({
              create: false,
              help: false,
            })
          }}
          className={`animate__animated animate__fadeIn animate__faster rounded-lg 
fixed mt-2  right-[5%] max-w-[75w] w-[300px] z-50  list-none bg-white divide-y divide-secCol1-50 lg:shadow`}
        >
          <h4 className="font-sans p-3  pb-2 font-medium text-md text-[16px]">Help</h4>
          <div className="flex flex-col  mt-1">
            <a
              href="https://selfpublishingtitans.com/support"
              target="_blank"
              className="hover:bg-[#c3abff] p-2 rounded-md flex items-center hover:text-gray-900 font-sans font-medium text-md text-[14.6px]"
            >
              <MessageIcon className="mr-3" />
              Support
            </a>
            <a
              href="https://selfpublishingtitans.com/support"
              target="_blank"
              className="hover:bg-[#c3abff] p-2 rounded-md flex items-center hover:text-gray-900 font-sans font-medium text-md text-[14.6px]"
            >
              <AddToPhotosIcon className="mr-3" />
              Feature Request
            </a>
            <a
              target="_blank"
              href="https://selfpublishingtitans.com/support"
              className="hover:bg-[#c3abff] p-2 rounded-md flex items-center hover:text-gray-900 font-sans font-medium text-md text-[14.6px]"
            >
              <BugReportIcon className="mr-3" />
              Bug Report
            </a>
            <a
              href="https://community.selfpublishingtitans.com/"
              target="_blank"
              className="hover:bg-[#c3abff] p-2 rounded-md flex items-center hover:text-gray-900 font-sans font-medium text-md text-[14.6px]"
            >
              <Group className="mr-3" />
              Community
            </a>
            <a
              href="https://www.youtube.com/@SelfPublishingTitans"
              target="_blank"
              className="hover:bg-[#c3abff] p-2 rounded-md flex items-center hover:text-gray-900 font-sans font-medium text-md text-[14.6px]"
            >
              <YouTubeIcon className="mr-3" />
              Tutorials
            </a>
          </div>
        </div>
      )}
    </div>
  )
}

export default Create
