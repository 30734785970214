import React, { useState, useCallback, useEffect, useRef } from "react"
import debounce from "lodash/debounce"
import ToolSearchData, { SectionItem } from "./searchData"
// import { getRequest } from "@/api/interface";
// import Link from "next/link"
import { Link } from "react-router-dom"
import { Search } from "@mui/icons-material"
// import { ApiResponse } from "@/models/interfaces/community";
import BlogPost from "~/models/interfaces/blog"
import { blogs as Blogs } from "~/data/blogs"
import DvrIcon from "@mui/icons-material/Dvr"
import ForumIcon from "@mui/icons-material/Forum"
import ArticleIcon from "@mui/icons-material/Article"

interface UserInfo {
  id: string
  username: string
  display_name: string
  rank: number
  status: string
}

interface Tag {
  slug_name: string
  display_name: string
  main_tag_slug_name: string
  recommend: boolean
  reserved: boolean
}

interface AnswerObject {
  id: string
  question_id: string
  title: string
  url_title: string
  excerpt: string
  created_at: number
  vote_count: number
  accepted: boolean
  answer_count: number
  user_info: UserInfo
  tags: Tag[]
  status: string
}

export interface ApiResponse {
  object_type: string
  object: AnswerObject
}

const fetchWithTimeout = async (url: any, options: any, timeout: any) => {
  const controller = new AbortController()
  const id = setTimeout(() => controller.abort(), timeout)
  const response = await fetch(url, {
    ...options,
    signal: controller.signal,
  })
  clearTimeout(id)
  return response
}

const fetchWithRetries = async (url: any, options: any, retries: any, timeout: any) => {
  for (let i = 0; i < retries; i++) {
    try {
      const response = await fetchWithTimeout(url, options, timeout)
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }
      return response.json()
    } catch (error) {
      if (i === retries - 1) {
        throw error
      }
    }
  }
}

const Index: React.FC = () => {
  const [showDropdown, setShowDropdown] = useState(false)
  const [searchLoading, setSearchLoading] = useState({
    tools: false,
    community: false,
  })

  const [query, setQuery] = useState("")
  const [blogs, setBlogs] = useState<BlogPost[]>([])

  const [toolResults, setToolResults] = useState<SectionItem[]>([])
  const [communityResults, setCommunityResults] = useState<ApiResponse[]>([])

  const dropdownRef = useRef<HTMLDivElement>(null)

  const filterData = (query: string) => {
    if (query === "") return setToolResults([])

    const filteredResults = ToolSearchData.filter((item: SectionItem) =>
      item.item.toLowerCase().includes(query.toLowerCase())
    ).slice(0, 5)

    setToolResults(filteredResults)
  }

  let abortController: AbortController | null = null

  const simulateApiRequest = async (query: string) => {
    if (query === "") return
    // setCommunityResults([]);
    const params = {
      q: query,
      page: 1,
      order: "relevance",
      size: 5,
    }

    if (abortController) {
      abortController.abort()
    }

    abortController = new AbortController()
    const signal = abortController.signal

    const url = `https://community.selfpublishingtitans.com/answer/api/v1/search?q=${params.q}&page=${params.page}&order=${params.order}&size=${params.size}`
    const options = {
      method: "GET",
      signal,
    }

    const data = await fetchWithRetries(url, options, 3, 15000)

    const CData = data.data.list as ApiResponse[]
    const results = CData
    return results
  }

  const fetchCommunityResults = async (query: string) => {
    setSearchLoading((prev) => ({ ...prev, community: true }))
    const results = await simulateApiRequest(query)
    setCommunityResults(results || [])
    setSearchLoading((prev) => ({ ...prev, community: false }))
  }

  const debouncedFetchCommunityResults = useCallback(debounce(fetchCommunityResults, 300), [])

  const filterBlogData = (query: string) => {
    const filteredResults = Blogs.filter((item: BlogPost) =>
      item.title.toLowerCase().includes(query.toLowerCase())
    ).slice(0, 5)

    setBlogs(filteredResults)
  }

  const handleInputChange = (event: any) => {
    const query = event.target.value
    setQuery(query)
    setShowDropdown(true)
    debouncedFilterData(query)
    debouncedFetchCommunityResults(query)
    filterBlogData(query)
  }

  const debouncedFilterData = useCallback(debounce(filterData, 0), [])

  function getCommunityLink(item: any) {
    const baseUrl = "https://community.selfpublishingtitans.com/questions/"
    if (item.object_type == "question") {
      return baseUrl + item.object.id
    }
    return baseUrl + item.object.question_id + "/" + item.object.id
  }

  const handleClickOutside = (event: any) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false)
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  return (
    <div
      style={{
        position: "relative",
      }}
      className="relative w-full sm:w-[250px] md:w-[250px] lg:w-[350px] font-sans"
      ref={dropdownRef}
    >
      <div className="relative mr-2">
        <input
          style={{
            border: "1px solid #ccc",
            borderRadius: "13px",
          }}
          type="text"
          className="border border-gray-300 outline-none rounded-md px-3 py-2.5 w-full pl-10"
          id="outlined-basic"
          placeholder="Search"
          onChange={handleInputChange}
          onFocus={() => setShowDropdown(true)}
          // autoComplete="off"
        />
        <Search className="absolute left-3 top-2.5 transform -translate-y-1/2 text-gray-400" style={{ fontSize: 20 }} />
      </div>
      {showDropdown && query && (
        <div className="fixed  right-[17%] mt-2 bg-white text-black border border-gray-300 w-[350px] rounded-md shadow-lg z-50">
          <div className="p-2">
            {searchLoading.tools && (
              <div className="flex items-center justify-center">
                <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-gray-900"></div>
              </div>
            )}
            {!searchLoading.tools && (
              <div className="flex items-center pt-2 px-2">
                <div className="font-bold text-[16px] flex gap-2 items-center">
                  <DvrIcon
                    style={{
                      fontSize: 18,
                    }}
                  />
                  Tools
                </div>
              </div>
            )}
            <div className="px-2 mt-2">
              <hr
                className=""
                style={{
                  borderTop: "1px solid #e2e8f0",
                  borderBottom: "1px solid #edf2f7",
                  borderLeft: "none",
                }}
              />
            </div>
            <div className="">
              {toolResults.map((item, index) => (
                <a href={item.productLink || ""} target="__blank" key={index}>
                  <div className="p-2 border-b border-gray-300 hover:bg-gray-200 rounded-lg cursor-pointer">
                    <div className="text-gray-800 font-semibold">{item.item}</div>
                    <div className="text-gray-500 truncate-text-1">{item.tag}</div>
                  </div>
                </a>
              ))}
              {toolResults.length === 0 && query && <div className="p-2 text-gray-500">No results found</div>}
            </div>
          </div>
          <div className="p-2 ">
            <div className="flex items-center px-2">
              <div className="font-bold text-[16px] flex gap-2 items-center">
                <ForumIcon
                  style={{
                    fontSize: 18,
                  }}
                />
                Community
              </div>
            </div>

            <div className="px-2 mt-2">
              <hr
                className=""
                style={{
                  borderTop: "1px solid #e2e8f0",
                  borderBottom: "1px solid #edf2f7",
                  borderLeft: "none",
                }}
              />
            </div>
            <div className="">
              {communityResults.map((item, index) => (
                <Link to={getCommunityLink(item)} target="__blank" key={index}>
                  <div className="p-2 border-b border-gray-300 hover:bg-gray-200 rounded-lg cursor-pointer">
                    <div className="text-gray-800 font-semibold truncate-text-2">
                      {item.object_type == "question" ? item.object.title : item.object.excerpt}
                    </div>
                    <div className="flex gap-2 pt-1">
                      <div className="text-gray-500 font-medium">Answers: {item.object.vote_count}</div>
                      <div className="text-gray-500 font-medium">Votes: {item.object.answer_count}</div>
                    </div>
                  </div>
                </Link>
              ))}
              {communityResults.length === 0 && query && !searchLoading.community && (
                <div className="p-2 text-gray-500">No results found</div>
              )}
              {searchLoading.community && <div className="p-2">loading...</div>}
            </div>
          </div>
          <div className="p-2">
            {
              <div className="flex items-center px-2">
                <div className="font-bold text-[16px] flex gap-2 items-center">
                  <ArticleIcon
                    style={{
                      fontSize: 18,
                    }}
                  />{" "}
                  Blog
                </div>
              </div>
            }
            <div className="px-2 mt-2">
              <hr
                className=""
                style={{
                  borderTop: "1px solid #e2e8f0",
                  borderBottom: "1px solid #edf2f7",
                  borderLeft: "none",
                }}
              />
            </div>
            <div className="">
              {blogs.map((item, index) => (
                <a href={item.link} target="__blank" key={index}>
                  <div className="p-2 border-b border-gray-300 hover:bg-gray-200 rounded-lg cursor-pointer">
                    <div className="text-gray-800 font-semibold">{item.title}</div>
                    {/* <div className="text-gray-500">{item.tag}</div> */}
                  </div>
                </a>
              ))}
              {blogs.length === 0 && query && <div className="p-2 text-gray-500">No results found</div>}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Index
