import React, { useEffect, useState } from "react"
import Avatar from "@mui/material/Avatar"
import PersonIcon from "@mui/icons-material/Person"
import usage from "~/api/usage"
import StarsRoundedIcon from "@mui/icons-material/StarsRounded"
import { Typography } from "@mui/material"
import EmojiEventsRoundedIcon from "@mui/icons-material/EmojiEventsRounded"
import WhatshotIcon from "@mui/icons-material/Whatshot"
// import { getLevelByXp } from "@/components/Profile/SkillLevel";
import SignalCellularAltOutlinedIcon from "@mui/icons-material/SignalCellularAltOutlined"

interface IAccountCircleProps {
  src?: string
  email?: string
  user_id?: string
}

const mainSite = "http://selfpublishingtitans.com"

const AccountIcon: React.FC<IAccountCircleProps> = ({ src, email, user_id }) => {
  const [userRank, setUserRank] = useState<number | null>(null)
  const [totalXp, setTotalXp] = useState<number | null>(null)
  const [streak, setStreak] = useState<number | null>(null)

  const getInitial = (email: string) => {
    return email.charAt(0).toUpperCase()
  }

  const fetchUserStat = async () => {
    if (!user_id) {
      return
    }

    try {
      const rankData = await usage.get_user_rank("this_month" as any, user_id)
      // @ts-ignore
      setUserRank(rankData.data.rank)
      const xpData = await usage.get_total_points(user_id)
      // @ts-ignore
      setTotalXp(xpData.data.total_xp)
      const streadData = await usage.get_current_streak(user_id)
      // @ts-ignore
      setStreak(streadData.data.current_streak || 0)
    } catch (error) {}
  }

  useEffect(() => {
    fetchUserStat()
  }, [user_id])

  return (
    <div
      style={{
        border: "1px solid #e0e0e0",
        borderRadius: "25px",
      }}
      className="bg-white flex gap-2 p-1"
      // className="bg-white flex gap-2"
    >
      {totalXp && (
        <div onClick={() => window.open(mainSite + "/profile", "_blank")} className="flex gap-1 items-center">
          <SignalCellularAltOutlinedIcon color="primary" />{" "}
          <Typography className="text-black">
            {" "}
            {/* {getLevelByXp(totalXp)} */}
            {totalXp}
          </Typography>
        </div>
      )}
      {/* {userRank && (
        <div className="flex gap-1 items-center">
          <EmojiEventsRoundedIcon color="primary" />{" "}
          <Typography className="text-black"> {userRank}</Typography>
        </div>
      )} */}
      {
        <div onClick={() => window.open(mainSite + "/profile", "_blank")} className="flex gap-1 items-center">
          <WhatshotIcon color="primary" /> <Typography className="text-black"> {streak}</Typography>
        </div>
      }

      {src ? (
        <Avatar src={src} style={{ width: 40, height: 40 }} />
      ) : email ? (
        <Avatar style={{ width: 40, height: 40 }}>{getInitial(email)}</Avatar>
      ) : (
        <Avatar style={{ width: 40, height: 40 }}>
          <PersonIcon />
        </Avatar>
      )}
    </div>
  )
}

export default AccountIcon
