import React, { useState, useRef, useEffect } from "react"
import ChromeStoreIcon from "~/image/chrome_store.png"

import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined"
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined"

const ChromeStore = () => {
  const [showPopup, setShowPopup] = useState(false)
  const popupRef = useRef<HTMLDivElement>(null)

  const handleClickOutside = (event: MouseEvent) => {
    if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
      setShowPopup(false)
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  const handleLinkClick = (e: React.MouseEvent) => {
    e.preventDefault()
    setShowPopup((prev) => !prev)
  }

  return (
    <div className="relative">
      <button
        style={{
          border: "none",
        }}
        className="mx-2 mr-3 flex rounded-lg items-center"
        onClick={handleLinkClick}
        onMouseEnter={() => setShowPopup(true)}
        onMouseLeave={() => setShowPopup(false)}
      >
        <img
          src={"/assets/logos/chrome_store.png"}
          width={35}
          height={35}
          style={{
            border: "none",
          }}
          className="rounded-lg"
          alt="Chrome Store"
        />
      </button>
      <div
        onMouseEnter={() => {
          if (showPopup) {
            setShowPopup(true)
          }
        }}
        className="absolute h-5 w-20 "
        style={{ top: "30px", right: "0px" }}
      />
      {showPopup && (
        <div
          onMouseLeave={() => setShowPopup(false)}
          ref={popupRef}
          className={`animate__animated animate__fadeIn animate__faster  
fixed  rounded-lg right-[15%] mt-2 max-w-[75w] p-2 pt-3 w-[300px] z-50  list-none bg-white divide-y divide-secCol1-50 lg:shadow`}
        >
          <h4 className="font-sans px-2 pt-1 font-medium text-md text-[16px]">Chrome Extensions</h4>
          <div className="flex flex-col  mt-2">
            <a
              target="_blank"
              href="https://chromewebstore.google.com/detail/titans-pro-amazon-kdp-key/mmdamlknnafgffhlobhlmiljonijdnid"
              className="hover:bg-[#c3abff] p-2 rounded-md flex items-center hover:text-gray-900 font-sans font-medium text-md text-[14.6px]"
            >
              <SearchOutlinedIcon className="mr-3" />
              Titans Pro - Pro Keyword Suggestions
            </a>

            <a
              target="_blank"
              href="https://chromewebstore.google.com/detail/titans-quick-view-amazon/eefljgmhgaidffapnppcmmafobefjece"
              className="hover:bg-[#c3abff] p-2 rounded-md flex items-center hover:text-gray-900 font-sans font-medium text-md text-[14.6px]"
            >
              <VerifiedOutlinedIcon className="mr-3" />
              Titans Quick View - Pro Search Results Data
            </a>
          </div>
        </div>
      )}
    </div>
  )
}

export default ChromeStore
