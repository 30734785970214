import React, { useState } from "react"
import { Typography } from "@mui/material"

import SolutionsData, { TCategory, TResourcesData, Item } from "./ResourcesData"
import ChevronRight from "@mui/icons-material/ChevronRight"

function ResourcesMenu() {
  const [selectedCategory, setSelectedCategory] = useState<TResourcesData>(SolutionsData[0])

  const handleCategoryClick = (category: TResourcesData) => {
    setSelectedCategory(category)
  }

  const flattenedItems = selectedCategory.Categories.flatMap((category) => category.Items)

  return (
    <div className="h-fit br-16 rounded-md w-[78vw]">
      <div className="w-full flex flex-row max-h-[88vh] thin-scrollbar overflow-y-auto">
        <div className="flex br-16 p-3 flex-row grid grid-cols-5 w-full">
          <div className="flex flex-col ">
            {SolutionsData.map((category: TResourcesData, index: number) => (
              <div
                key={index}
                className={`flex rounded-xl py-2 px-2 my-1 justify-between text-black cursor-pointer ${
                  selectedCategory.Title === category.Title ? "bg-[#c3abff]" : "bg-white"
                } category-item`}
                onMouseEnter={() => handleCategoryClick(category)}
              >
                <Typography variant="body1">{category.Title}</Typography>
                <ChevronRight />
              </div>
            ))}
          </div>
          <div className="flex flex-col col-span-4 w-full px-4 transition-height">
            {selectedCategory ? (
              // @ts-ignore
              <div className="category-content w-full">
                <div className={`grid grid-cols-4 gap-2`}>
                  {flattenedItems.map((item: Item, index: number) => (
                    <div key={index} className=" w-full p-2">
                      <a href={item.link} className="" target={item.newTab ? "_blank" : "_self"}>
                        <div className="bg-white text-black hover:bg-[#c3abff] rounded-lg p-2">
                          <Typography variant="body1" className="font-bold text-black">
                            {item.heading}
                          </Typography>
                          <Typography variant="body2" className="text-black">
                            {item.tag}
                          </Typography>
                        </div>
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <Typography variant="h6">Select a category to view details</Typography>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResourcesMenu
